@import url('https://fonts.googleapis.com/css?family=Lato|Playfair+Display&display=swap');
$family-primary: 'Lato';
$family-secondary: 'Playfair Display';
@import '~bulma/bulma.sass';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
    margin: 0;
    font-family: 'Lato', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system, BlinkMacSystemFont,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

iframe {
    height: inherit;
}

.has-letter-spacing {
    letter-spacing: 2px;
}

.is-top-right {
    top: 0;
    right: 0;
    bottom: unset;
    left: unset;
}

.has-newline {
    white-space: pre-line;
}